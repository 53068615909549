module.exports = [{
      plugin: require('../../../node_modules/@halkeye/gatsby-rehype-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"icon":"<ion-icon name=\"link-outline\"></ion-icon>","offsetY":0,"className":"anchor","prependId":""},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://plugins.jenkins.io/"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://jenkins-matomo.do.g4v.dev","siteUrl":"https://plugins.jenkins.io/","respectDnt":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
